// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD0PqnN0jTxbeMQfgmWYJ93jvSfVNUzvXs",
  authDomain: "sushi4u-fe-46998.firebaseapp.com",
  projectId: "sushi4u-fe-46998",
  storageBucket: "sushi4u-fe-46998.appspot.com",
  messagingSenderId: "345859273537",
  appId: "1:345859273537:web:a545648f19d6e5c28ca7c0",
  measurementId: "G-N0ZVVL9ETE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get the Firestore instance
const db = getFirestore(app);

export default db; // Export the Firestore instance
